<template>
  <div v-if="typeof hssContentArray!='undefined'">
    <div v-for="(hssSingleContent,index) in hssContentArray" :key="index">
      <div>{{language.componentLabel.labelIMSI}}: {{(hssSingleContent["n_imsi"])}}</div>

      <div>{{language.componentLabel.labelKey}}: {{getContent(hssSingleContent)}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["row"],
  computed: {
    hssContentArray() {
      return this.row.hssContent;
    },
  },
  methods: {
    getContent(hssSingleContent) {
      let object = JSON.parse(hssSingleContent["c_obj"]);
      return object["K"];
    },
  },
};
</script>
<template>
  <div v-if="row.n_type_original==1  ">
    <div v-if="!empty(row.hssContent)" class="hssContentContainer">
      <div class="inlineDiv top subscriberAdditionalInfoTitleContainer">HSS</div>
      <div class="inlineDiv">
        <hssDataContent :row="row"></hssDataContent>
      </div>
    </div>

    <div v-if="!empty(row.ldapContent)">
      <div class="inlineDiv top subscriberAdditionalInfoTitleContainer">LDAP</div>
      <div class="inlineDiv">
        <ldapContent :row="row"></ldapContent>
      </div>
    </div>
  </div>
  <div v-else-if="row.n_type_original==2">
    <div v-for="(subscriber, i) in row.subscriberList" :key="i">
      :subLenght="subLenght+subscriber.lenght"
      <span
        v-if="subLenght<60 && $i< count(row.subscriberList)-1"
      >{{subscriber}},</span>
    </div>
    <span v-if="subLenght>60">...</span>
  </div>
</template>
<script>
import hssDataContent from "@/components/legacy/hssDataContent.vue";
import ldapContent from "@/components/legacy/ldapContent.vue";
export default {
  components: { hssDataContent, ldapContent },
  props: ["header", "row", "currentTable"],
  data() {
    return { subLenght: 0 };
  },
};
</script>
<template>
  <div v-if="typeof ldapContentArray != 'undefined'">
    <div v-for="(ldapContent, index) in ldapContentArray" :key="index">
      <div>{{language.ldapStep.filter}}: {{ ldapContent.c_filter }}</div>
      <div>{{language.ldapStep.base}}: {{ ldapContent.c_base }}</div>

      <div v-if="Array.isArray(ldapContent.c_obj)">
        <div>{{language.ldapStep.attributes}}:</div>

        <span v-for="(attributes, field) in ldapContent.c_obj" :key="field">{{
          field
        }}</span
        >:
        <span>{{ attributes }}</span>
      </div>
    </div>
  </div>
</template>




<script>
export default {
  props: ["row"],
  computed: {
    ldapContentArray() {
      return this.row.ldapContent;
    },
  },
};
</script>